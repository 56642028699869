import Loadable from "@axiomhq/react-loadable"
import React from "react"
import Loading from '../components/Loading/Global'

const Calendar = Loadable({
	loader  : () => import("../containers/Calendar"),
	loading : () => <Loading />
})

const OrgCalendar = Loadable({
	loader  : () => import("../containers/Calendar/OrgCalendar"),
	loading : () => <Loading />
})

const WorkSchedule = Loadable({
	loader  : () => import("../containers/Calendar/WorkSchedule"),
	loading : () => <Loading />
})

export const calendarLoadables = {
	Calendar,
	OrgCalendar,
	WorkSchedule
}
