import Loadable from "@axiomhq/react-loadable"
import React from "react"
import Loading from '../components/Loading/Global'

const LoadableHelp = Loadable({
	loader  : () => import("../containers/HelpSection"),
	loading : () => <Loading />
})

const LoadableHelpPost= Loadable({
	loader  : () => import("../containers/HelpSection/HelpPost"),
	loading : () => <Loading />
})

const LoadableCreatePost= Loadable({
	loader  : () => import("../containers/HelpSection/CreatePost/"),
	loading : () => <Loading />
})

const LoadableManageHelp= Loadable({
	loader  : () => import("../containers/HelpSection/ManageHelp/"),
	loading : () => <Loading />
})

const LoadableModifyTags= Loadable({
	loader  : () => import("../containers/HelpSection/ManageHelp/HelpTagTable"),
	loading : () => <Loading />
})

export const helpSectionLoadables = {
	LoadableHelp,
	LoadableHelpPost,
	LoadableCreatePost,
	LoadableManageHelp,
	LoadableModifyTags
}