import { from } from "core-js/features/array";

export * from './home'
export * from './account'
export * from './header'
export * from './footer'
export * from './register'
export * from './login'
export * from './profile'
export * from './appeals'
export * from './question'
export * from './helpSection'
export * from './management' 
export * from './messageToAdmin'
export * from './contacts'
export * from './news'
export * from './calendar'
export * from './uploadFile'
export * from './resolution'