import Loadable from '@axiomhq/react-loadable'
import React from 'react'
import Loading from '../components/Loading/Global'

const LoadableContacts = Loadable({
	loader  : () => import('../containers/Contacts'),
	loading : () => <Loading />
})

const LoadableSetContacts = Loadable({
	loader  : () => import('../containers/Contacts/SetContacts'),
	loading : () => <Loading />
})
const LoadableContactsForm = Loadable({
	loader  : () => import('../containers/Contacts/ContactsForm'),
	loading : () => <Loading />
})
export const contactsLoadables = {
	LoadableContacts,
	LoadableSetContacts,
	LoadableContactsForm
}
