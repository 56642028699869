import Loadable from '@axiomhq/react-loadable'
import React from 'react'
import Loading from '../components/Loading/Global'

const LoadableUploadFileExample = Loadable({
	loader  : () => import('../containers/UploadFile/example'),
	loading : () => <Loading />
})

export const uploadFileLoadables = {
	LoadableUploadFileExample
}