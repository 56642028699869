import Loadable from "@axiomhq/react-loadable"
import React from "react"
import Loading from '../components/Loading/Global'

const Users = Loadable({
	loader  : () => import("../containers/Management/Users"),
	loading : () => <Loading />
})

const User = Loadable({
	loader  : () => import("../containers/Management/User"),
	loading : () => <Loading />
})

const OrgUser = Loadable({
	loader  : () => import("../containers/Management/OrgUser"),
	loading : () => <Loading />
})

const ContentManagement = Loadable({
	loader  : () => import("../containers/ContentManagement"),
	loading : () => <Loading />
})

const MessageTemplates = Loadable({
	loader  : () => import("../containers/MessageTemplates"),
	loading : () => <Loading />
})

const OrgUsers = Loadable({
	loader  : () => import("../containers/Management/OrgUsers"),
	loading : () => <Loading />
})

const RemovedOrgUsers = Loadable({
	loader  : () => import("../containers/Management/RemovedOrgUsers"),
	loading : () => <Loading />
})

const OrgManagement = Loadable({
	loader  : () => import("../containers/OrgManagement"),
	loading : () => <Loading />
})

const InvitationToReOrg = Loadable({
	loader  : () => import("../containers/Management/InvitationToReOrg"),
	loading : () => <Loading />
})

const Invitation = Loadable({
	loader  : () => import("../containers/Management/InvitationToReOrg/invitation"),
	loading : () => <Loading />
})

const DisciplinaryActionsList = Loadable({
	loader  : () => import("../containers/Management/DisciplinaryActions/list"),
	loading : () => <Loading />
})

const ReOrgReSubthemeOgulsList = Loadable({
	loader  : () => import("../containers/Management/ReOrgReSubthemeOguls/list"),
	loading : () => <Loading />
})

const ChildOrgs = Loadable({
	loader  : () => import("../containers/Management/ChildOrgs"),
	loading : () => <Loading />
})
const ChildOrgInvitation = Loadable({
	loader  : () => import("../containers/Management/ChildOrgs/invitation"),
	loading : () => <Loading />
})
const ReportManagement = Loadable({
	loader  : () => import("../containers/Management/Reports"),
	loading : () => <Loading />
})

const WebApi = Loadable({
	loader  : () => import('../containers/Management/WebApi'),
	loading : () => <Loading />
})

const OrganizationUnits = Loadable({
	loader  : () => import('../containers/Management/ReOrganizationUnits'),
	loading : () => <Loading />
})

const AisMvManage = Loadable({
	loader  : () => import("../containers/OrgManagement/AisMvManage"),
	loading : () => <Loading />
})

const ControlSubsystem = Loadable({
	loader  : () => import("../containers/ControlSubsystem"),
	loading : () => <Loading />
})

const ControlSubsystemOrg = Loadable({
	loader  : () => import("../containers/ControlSubsystem/ViewAppealsOrganization"),
	loading : () => <Loading />
})

const ControlSubsystemOrgAppeal = Loadable({
	loader  : () => import("../containers/ControlSubsystem/OneAppeal"),
	loading : () => <Loading />
})

const AccessRightsAssignment = Loadable({
	loader  : () => import("../containers/AccessRightsAssignment"),
	loading : () => <Loading />
})

export const managementLoadables = {
	AccessRightsAssignment,
	AisMvManage,
	ChildOrgInvitation,
	ChildOrgs,
	ContentManagement,
	ControlSubsystem,
	ControlSubsystemOrg,
	ControlSubsystemOrgAppeal,
	DisciplinaryActionsList,
	Invitation,
	InvitationToReOrg,
	MessageTemplates,
	OrgManagement,
	OrgUser,
	OrgUsers,
	OrganizationUnits,
	ReOrgReSubthemeOgulsList,
	RemovedOrgUsers,
	ReportManagement,
	User,
	Users,
	WebApi
}
