import Loadable from "@axiomhq/react-loadable"
import React from "react"
import Loading from '../components/Loading/Global'

const Register = Loadable({
	loader  : () => import("../containers/Register"),
	loading : () => <Loading />
})

const RegisterLocked = Loadable({
	loader  : () => import("../containers/RegisterLocked"),
	loading : () => <Loading />
})

const RegisterByAdmin = Loadable({
	loader  : () => import("../containers/RegisterByAdmin"),
	loading : () => <Loading />
})

const RegisterByExternalAdmin = Loadable({
	loader  : () => import("../containers/RegisterByExternalAdmin"),
	loading : () => <Loading />
})

const RestorePassword = Loadable({
	loader  : () => import("../containers/Profile/Password/Restore"),
	loading : () => <Loading />
})

/*
 * const RegisterIDLUser = Loadable({
 *   loader: () => import("../containers/RegisterIDLUser"),
 *   loading: () => <>Loading...</>,
 * });
 */

/*
 * const RegisterIDLOrg = Loadable({
 *   loader: () => import("../containers/RegisterIDLOrg"),
 *   loading: () => <>Loading...</>,
 * });
 */

export const registerLoadables = {
	Register,
	RegisterLocked,
	RestorePassword,
	RegisterByAdmin,
	RegisterByExternalAdmin

	/*
	 * RegisterIDLUser,
	 * RegisterIDLOrg
	 */
}
