import Loadable from '@axiomhq/react-loadable'
import React from 'react'
import Loading from '../components/Loading/Global'
const LoadableAppealsForm = Loadable({
	loader  : () => import('../containers/Appeals/AppealsForm'),
	loading : () => <Loading />
})
const LoadableAppealCreateForm = Loadable({
	loader  : () => import('../containers/Appeals/AppealCreateForm'),
	loading : () => <Loading />
})

const LoadableAppealEditForm = Loadable({
	loader  : () => import("../containers/Appeals/AppealEditForm"),
	loading : () => <Loading />
})

const LoadableAppeals = Loadable({
	loader  : () => import('../containers/Appeals'),
	loading : () => <Loading />
})

const LoadableOneAppealView = Loadable({
	loader  : () => import('../containers/Appeals/OneAppealView'),
	loading : () => <Loading />
})

const LoadableRegForm = Loadable({
	loader  : () => import('../containers/Appeals/RegForm'),
	loading : () => <Loading />
})

const CollectiveAppealsList = Loadable({
	loader  : () => import('../containers/Appeals/CollectiveAppeal'),
	loading : () => <Loading />
})

const CollectiveAppealView = Loadable({
	loader  : () => import('../containers/Appeals/CollectiveAppeal/viewOne'),
	loading : () => <Loading />
})

const JointToAppeal = Loadable({
	loader  : () => import('../containers/Appeals/CollectiveAppeal/jointToAppeal'),
	loading : () => <Loading />
})

const PrivateJointToAppeal = Loadable({
	loader  : () => import("../containers/Appeals/CollectiveAppeal/privateJointToAppeal"),
	loading : () => <Loading />
})

const LoadableAppealRequestForms = Loadable({
	loader  : () => import("../containers/Appeals/RedirectAppeal/AppealRequestForms/all"),
	loading : () => <Loading />
})

export const appealLoadables = {
	LoadableAppealsForm,
	LoadableAppeals,
	LoadableOneAppealView,
	LoadableRegForm,
	LoadableAppealCreateForm,
	CollectiveAppealsList,
	CollectiveAppealView,
	JointToAppeal,
	PrivateJointToAppeal,
	LoadableAppealEditForm,
	LoadableAppealRequestForms
}
