import Loadable from "@axiomhq/react-loadable"
import React from "react"
import Loading from '../components/Loading/Global'

const Login = Loadable({
	loader  : () => import("../containers/Login"),
	loading : () => <Loading />
})

const RedirectPage = Loadable({
	loader  : () => import("../containers/ExternalLogin/redirectPage"),
	loading : () => <Loading />
})

export const loginLoadables = {
	Login,
	RedirectPage
}
