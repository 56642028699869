import Loadable from '@axiomhq/react-loadable'
import React from 'react'
import Loading from '../components/Loading/Global'

const LoadableMessageToAdmin = Loadable({
	loader  : () => import('../containers/MessageToAdmin'),
	loading : () => <Loading />
})

export const messageToAdminLoadables = {
	LoadableMessageToAdmin
}
