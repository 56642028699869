import Loadable from "@axiomhq/react-loadable"
import React from "react"
import Loading from '../components/Loading/Global'

const Profile = Loadable({
	loader  : () => import("../containers/Profile"),
	loading : () => <Loading />
})

const ConfirmEmail = Loadable({
	loader  : () => import("../containers/Profile/Email/Confirm"),
	loading : () => <Loading />
})

export const profileLoadables = {
	Profile,
	ConfirmEmail
}
